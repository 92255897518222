import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { CartContext } from "@context/CartContext";

// styling
import "./CheckoutResult.css";

// icons/images
import CheckCircle from "@images/icons/check-circle.svg";
import SadFace from "@images/icons/sad-face.svg";

export default function CheckoutResult(props) {
  const { setCart } = useContext(CartContext);
  const { checkoutSuccess } = props;

  useEffect(() => {
    if (checkoutSuccess) {
      setCart([]);
      const cartName =
        window.location.hostname === "localhost"
          ? "local-scc-cart"
          : "scc-cart";
      localStorage.removeItem(cartName);
    }
  }, []);

  return (
    <div className="checkout-result">
      {checkoutSuccess ? (
        <>
          <img
            draggable="false"
            src={CheckCircle}
            className="checkmark"
            alt="checkmark"
          />
          <h1>Checkout Confirmed</h1>
          <p>
            Thank you, we'll be sure to fulfill your order as soon as possible.
          </p>
          <p>
            While you wait, check out our <Link to="/blog">blog</Link>.
          </p>
        </>
      ) : (
        <>
          <img
            draggable="false"
            src={SadFace}
            className="sad-face"
            alt="checkmark"
          />
          <h1>Order Canceled</h1>
          <p>
            The order was cancelled. Your cart has been saved in case you'd like
            to <a href="/checkout">try again</a>.
          </p>
          <p>
            <a href="mailto:support@so-calledcivilized.com?bcc=ckmontesano@gmail.com&subject=Issue%20with%20SCC%20Website">
              Did something go wrong?
            </a>
          </p>
        </>
      )}
    </div>
  );
}
