import React, { useContext, useEffect, useState, useRef } from "react";
import axios from "axios";
import { AdminContext } from "./AdminContext";
import getApiUrl from "./logic/apiUrl";

import Order from "./components/Order";

export default function Console() {
  const { loginPair, usernamePair } = useContext(AdminContext);
  const { setLoggedIn } = loginPair;
  const { username, setUsername } = usernamePair;

  const [adminName, setAdminName] = useState("Anonymous");
  const [setError] = useState("");
  const [orders, setOrders] = useState(null);

  const apiUrl = getApiUrl();

  // get user first and last name
  function getUserInfo() {
    if (username !== "") {
      axios({
        method: "post",
        url: apiUrl + "/admin/get_user_info",
        data: {
          username: username,
        },
      })
        .then((res) => {
          setAdminName(res.data.full_name);
        })
        .catch(() => {
          setError(
            "There was a problem connecting to the server. Please try again later.",
          );
        });
    }
  }

  useEffect(() => {
    getUserInfo();
  }, []);

  function getOrders() {
    axios.get(apiUrl + "/admin/orders").then((res) => {
      setOrders(res.data);
    });
  }

  function logout() {
    setLoggedIn(false);
    setUsername("");
    window.localStorage.setItem("loggedIn", false);
    window.localStorage.setItem("username", "");
  }

  useEffect(() => {
    getOrders(true);
  }, []);

  return (
    <div>
      <div className="flex flex-row justify-between bg-slate-900 px-8 py-2 lg:px-28 lg:py-16">
        <div>
          <span className="text-xl font-bold text-slate-100 md:text-2xl lg:text-3xl">
            SCC Admin Console
          </span>
          <div className="text-slate-500">
            <h1>Welcome, {adminName.split(" ")[0]}.</h1>
          </div>
        </div>
        <div>
          <button
            className="flex content-center gap-2 rounded-xl px-4 py-2.5 text-red-400 transition hover:bg-red-500 hover:text-slate-100"
            onClick={logout}
          >
            <span>Logout</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="px-8 py-12 lg:px-28 lg:py-16">
        <h1 className="text-5xl font-bold">Orders</h1>

        {orders !== null && orders.length !== 0 ? (
          orders.map((order, key) => {
            return <Order order={order} key={key} />;
          })
        ) : (
          <span className="text-slate-500">
            There are no orders to display.
          </span>
        )}
      </div>
    </div>
  );
}
