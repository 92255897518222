import React, { useContext, useEffect, useState } from "react";
import { CartContext } from "@context/CartContext";

// styling
import "./Checkout.css";

// components
import CartReview from "./Partials/CartReview";
import ContactInformation from "./Partials/ContactInformation";
import DeliveryInformation from "./Partials/DeliveryInformation";
import CartTotal from "./Partials/CartTotal";
import CheckoutResult from "./Partials/CheckoutResult";

export default function Checkout() {
  const [checkoutSuccess, setCheckoutSuccess] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [customerInfo, setCustomerInfo] = useState(null);
  const [deliveryInfo, setDeliveryInfo] = useState(null);
  const { cart } = useContext(CartContext);

  useEffect(() => {
    // check for checkout result
    if (window.location.search !== "") {
      const queryParameters = new URLSearchParams(window.location.search);
      const success = queryParameters.get("success");

      if (success === "true") {
        setCheckoutSuccess(true);
      } else {
        console.log("Checkout failure");
        setCheckoutSuccess(false);
      }
    }
  }, []);

  return (
    <div className="checkout">
      {checkoutSuccess === null ? (
        <>
          {currentStep === 0 && <CartReview />}
          {currentStep === 1 && (
            <ContactInformation setCustomerInfo={setCustomerInfo} />
          )}
          {currentStep === 2 && (
            <DeliveryInformation setDeliveryInfo={setDeliveryInfo} />
          )}
          {currentStep === 3 && (
            <CartTotal
              customerInfo={customerInfo}
              deliveryInfo={deliveryInfo}
            />
          )}
          {cart.length !== 0 && currentStep > 0 && (
            <button onClick={() => setCurrentStep(currentStep - 1)}>
              Back
            </button>
          )}
          {cart.length !== 0 && currentStep < 3 && (
            <button onClick={() => setCurrentStep(currentStep + 1)}>
              Next
            </button>
          )}
        </>
      ) : (
        <CheckoutResult checkoutSuccess={checkoutSuccess} />
      )}
    </div>
  );
}
