import { useContext, useEffect, useState } from "react";
import getApiUrl from "../logic/apiUrl";
import { AdminContext } from "../AdminContext";
import axios from "axios";
import Swal from "sweetalert2";
import SwalUtils from "@components/SwalUtils";

export default function Order(props) {
  const { order } = props;
  const [trackingLink, setTrackingLink] = useState("");
  const [orderStatusColor, setOrderStatusColor] = useState("text-slate-400");
  const [orderDeleted, setOrderDeleted] = useState(false);

  const { usernamePair } = useContext(AdminContext);

  const apiUrl = getApiUrl();

  // todo: this does not currently work
  async function getPaymentStatus() {
    const response = await axios.get(apiUrl + "/admin/check_payment_status", {
      params: {
        orderId: order._id,
      },
    });
    console.log(response.data);
  }

  async function deleteOrder() {
    Swal.fire({
      ...SwalUtils.universal,
      ...SwalUtils.types["deletePrompt"],
      ...{
        title: "Are you sure you want to permanently delete this order?",
        text: "This cannot be undone.",
        icon: "warning",
        showCancelButton: true,
      },
    }).then(async (deleteItem) => {
      if (deleteItem.isConfirmed) {
        try {
          const response = await axios.delete(apiUrl + "/admin/delete_order", {
            data: { orderId: order._id },
          });
          if (response.data.success) setOrderDeleted(true);
          // todo: update component to say it was deleted
        } catch (error) {
          console.error("Error deleting order:", error);
        }
      }
    });
  }

  useEffect(() => {
    getPaymentStatus();

    setTrackingLink(
      "https://www.ups.com/track?loc=en_US&tracknum=" +
        order.tracking_number +
        "&requester=ST/",
    );

    switch (order.order_status) {
      case "pending":
        setOrderStatusColor("text-yellow-600");
        break;
      case "confirmed":
        setOrderStatusColor("text-green-600");
        break;
      case "shipped":
        setOrderStatusColor("text-blue-500");
        break;
      case "delivered":
        setOrderStatusColor("text-slate-400");
        break;
      default:
        setOrderStatusColor("text-slate-900");
    }
  }, []);

  return (
    <div className="my-6 bg-slate-100 p-8 shadow-md">
      {!orderDeleted ? (
        <>
          <div className="flex flex-row justify-between">
            <span className="block text-2xl font-bold">
              {order.customer_information.full_name}
            </span>
            <span className={"block text-xl " + orderStatusColor}>
              {order.order_status.charAt(0).toUpperCase() +
                order.order_status.slice(1) || "None"}
            </span>
          </div>

          <span className="text-xl">
            <span>
              {order.tracking_number !== null ? (
                <span className="font-medium">
                  Tracking Number:{" "}
                  <a
                    href={trackingLink}
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    {order.tracking_number}
                  </a>
                </span>
              ) : (
                <span className="text-slate-400">No Tracking Number</span>
              )}
            </span>
          </span>
          <br />
          <div className="mt-5">
            {order.items.map((item, key) => {
              return (
                <div className="mb-5 flex flex-row" key={key}>
                  <img
                    src={item.product.fields.itemImages[0].fields.file.url}
                    className="mr-5 inline-block h-28 w-28 rounded-2xl object-cover shadow-md"
                    alt={item.product.fields.itemName}
                  />
                  <div className="flex flex-col justify-center">
                    <span className="font-bold">
                      {item.product.fields.itemName}
                    </span>
                    <span>Quantity: {item.quantity}</span>
                    <span>{item.size && `Size: ${item.size}`} </span>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="mt-10 border-t-2 border-slate-200 pt-3">
            {usernamePair.username === "ckmontesano" && (
              <span
                onClick={deleteOrder}
                className="cursor-pointer text-red-600 hover:underline"
              >
                Delete Order
              </span>
            )}
          </div>
        </>
      ) : (
        <span>Order was deleted.</span>
      )}
    </div>
  );
}
